import { defineStore } from 'pinia'
import type { NotificationDTO } from '@/generated'
import { computed, type Ref, ref } from 'vue'
import { useNoticeStore } from '@/stores/notice'
import { NoticeType } from '@/stores/models/Notice'
import { NotificationService } from '@/services/telehealthApi/NotificationService'
import { NotificationStatusDTO } from '@/generated'

export const useNotificationStore = defineStore('notification', () => {
  const noticeStore = useNoticeStore()

  const notifications: Ref<NotificationDTO[]> = ref([])

  const newNotificationsAvailable = computed<boolean>(() => {
    return notifications.value.some(
      (notification) => notification.status === NotificationStatusDTO.UNREAD
    )
  })

  async function refreshNotificationList(): Promise<void> {
    try {
      notifications.value = (await NotificationService.getNotifications()).notifications
    } catch (error: any) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Failed to load notifications'
      })
      notifications.value = []
    }
  }

  async function markNotificationsAsRead(latestNotificationId: string): Promise<void> {
    try {
      await NotificationService.markNotificationsStatusAsRead(latestNotificationId)
    } catch (error: any) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'There was an error while marking notifications as read'
      })
    }
  }

  return {
    notifications,
    newNotificationsAvailable,
    refreshNotificationList,
    markNotificationsAsRead
  }
})
