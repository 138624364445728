import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, type Ref, ref, watch } from 'vue'
import type {
  CaseDTO,
  GetVitalSignsResponse,
  HospitalDTO,
  VitalSignsWithTypesDTO
} from '@/generated'
import { APIService } from '@/services/telehealthApi/APIService'
import { arrayToRecord } from '@/util/convertArrayToRecord'
import { useNoticeStore } from '@/stores/notice'
import { NoticeType } from '@/stores/models/Notice'
import type { PatientFilter } from '@/stores/models/PatientFilter'
import { remove } from 'lodash'
import { useLocalStorage } from '@vueuse/core'
import type { VitalSignsSortBy } from './models/VitalSignsSortBy'
import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'
import { compareCases } from '@/util/compareCases'

export type CaseWithVitalSigns = CaseDTO & GetVitalSignsResponse

export const useCaseStore = defineStore('case', () => {
  const casesLoading = ref(false)
  const vitalSignsLoading = ref(false)

  const cases: Ref<Record<string, CaseDTO>> = ref({})
  const vitalSigns: Ref<Record<string, CaseWithVitalSigns>> = ref({})

  const patientFilter: Ref<PatientFilter> = useLocalStorage('patientFilter', {
    hospitals: [],
    departments: [],
    wards: []
  })

  const patientFilterInput: Ref<PatientFilter> = ref({ hospitals: [], departments: [], wards: [] })

  const vitalSignsSortBy: Ref<VitalSignsSortBy> = useLocalStorage('vitalSignsSortBy', {
    sortBy: VitalSignsKeys.HEART_RATE,
    direction: 'DESC'
  })
  const vitalSignsSortByInput: Ref<VitalSignsSortBy> = ref({
    sortBy: VitalSignsKeys.HEART_RATE,
    direction: 'DESC'
  })

  function sortVitalSignsMeasurementDates(vitalSigns: VitalSignsWithTypesDTO[]) {
    return vitalSigns.map((v) => {
      return {
        ...v,
        items: v.items.sort(
          (a, b) => new Date(b.measurementDate).getTime() - new Date(a.measurementDate).getTime()
        )
      }
    })
  }

  function sortVitalSignsNumericValues(a: CaseWithVitalSigns, b: CaseWithVitalSigns): number {
    const getVitalSignValue = (caseData: CaseWithVitalSigns): number | null => {
      const vitalSign = caseData.vitalSigns.find(
        (v) => v.vitalSignType.typeKey === vitalSignsSortBy.value.sortBy
      )
      return vitalSign?.items[0]?.numericValue ?? null
    }

    const aValue = getVitalSignValue(a)
    const bValue = getVitalSignValue(b)

    if (aValue === null && bValue === null) return 0
    if (aValue === null) return 1
    if (bValue === null) return -1

    const sortDirection = vitalSignsSortBy.value.direction === 'ASC' ? 1 : -1
    return (aValue - bValue) * sortDirection
  }

  const caseList = computed<CaseDTO[]>(() => Object.values(cases.value))

  const caseWithVitalSignsList = computed<CaseWithVitalSigns[]>(() => {
    const casesWithVitalSigns = Object.values(vitalSigns.value)

    const sortedCasesWithVitalSigns = casesWithVitalSigns
      .map((caseWithVitalSigns) => {
        const { vitalSigns, ...rest } = caseWithVitalSigns
        return {
          ...rest,
          vitalSigns: sortVitalSignsMeasurementDates(vitalSigns)
        }
      })
      .sort(compareCases)

    if (vitalSignsSortBy.value.sortBy === VitalSignsKeys.PATIENT_NAME) {
      return sortedCasesWithVitalSigns
    }

    return sortedCasesWithVitalSigns.sort(sortVitalSignsNumericValues)
  })

  async function refreshCases() {
    try {
      casesLoading.value = true
      cases.value = arrayToRecord((await APIService.getCases()).cases)
    } catch (error: any) {
      cases.value = {}
      useNoticeStore().add({ type: NoticeType.ERROR, message: 'Failed to load patient data' })
    } finally {
      casesLoading.value = false
    }
  }

  async function getVitalSigns() {
    try {
      await refreshCases()

      vitalSignsLoading.value = true
      const vitalSignsPromises = await Promise.all(
        caseList.value.map(async (caze) => {
          return await getVitalSignsByCaseId(caze.id)
        })
      )
      vitalSignsLoading.value = false

      vitalSignsPromises.map((response, index) => {
        const caze = caseList.value[index]
        vitalSigns.value = {
          ...vitalSigns.value,
          [caze.id]: {
            ...caze,
            vitalSigns: response.vitalSigns
          }
        }
      })
    } catch (error: any) {
      vitalSigns.value = {}
      useNoticeStore().add({ type: NoticeType.ERROR, message: 'Failed to load vital signs' })
    }
  }

  async function getVitalSignsByCaseId(caseId: string): Promise<GetVitalSignsResponse> {
    return await APIService.getVitalSigns(caseId)
  }

  function getCaseById(id: string): CaseDTO | undefined {
    return cases.value[id]
  }

  function commitPatientFilterInput() {
    patientFilter.value = { ...patientFilterInput.value }
  }

  function commitVitalSignsSortByInput() {
    vitalSignsSortBy.value = { ...vitalSignsSortByInput.value }
  }

  function resetPatientFilterInput() {
    patientFilterInput.value = { ...patientFilter.value }
  }

  function cleanupPatientFilter() {
    cleanupFilter(patientFilter.value)
  }

  function cleanupPatientFilterInput() {
    cleanupFilter(patientFilterInput.value)
  }

  function cleanupFilter(filter: PatientFilter) {
    const caseListFilteredByHospitals = caseList.value.filter((caze) =>
      filter.hospitals.some((hospital) => hospital.id === caze.hospital.id)
    )

    filter.wards = filter.wards.filter((wardName) => {
      return caseListFilteredByHospitals.some((caze) => caze.ward.name === wardName)
    })

    filter.departments = filter.departments.filter((departmentName) => {
      return caseListFilteredByHospitals.some((caze) => caze.department.name === departmentName)
    })
  }

  function removeHospitalFilter(hospitalId: string) {
    remove(patientFilter.value.hospitals, (h: HospitalDTO) => h.id === hospitalId)
    cleanupPatientFilter()
  }

  function removeDepartmentFilter(department: string) {
    remove(patientFilter.value.departments, (d: string) => d === department)
  }

  function removeWardFilter(ward: string) {
    remove(patientFilter.value.wards, (w: string) => w === ward)
  }

  watch(
    () => patientFilterInput.value.hospitals,
    () => {
      cleanupPatientFilterInput()
    }
  )

  return {
    casesLoading,
    vitalSignsLoading,
    cases,
    caseList,
    patientFilter,
    patientFilterInput,
    commitPatientFilterInput,
    resetPatientFilterInput,
    refreshCases,
    getCaseById,
    removeHospitalFilter,
    removeDepartmentFilter,
    removeWardFilter,
    getVitalSigns,
    caseWithVitalSignsList,
    vitalSigns,
    getVitalSignsByCaseId,
    commitVitalSignsSortByInput,
    vitalSignsSortBy,
    vitalSignsSortByInput
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCaseStore, import.meta.hot))
}
