<template>
  <div class="min-w-[700px]">
    <div
      class="grid grid-cols-8 gap-4 text-body-1 !text-xs text-secondary-text bg-grey-100 py-2 px-6 mt-2"
    >
      <span class="col-span-2 place-self-center">{{ t('SAP / DAP / MAP  (mmHg)') }}</span>
      <span class="col-span-2 place-self-center">{{ t('SBP / DBP / MAP noninv. (mmHg)') }}</span>
      <span class="col-span-1 place-self-center">{{ t('HF (1/min)') }}</span>
      <span class="col-span-1 place-self-center">{{ t('SpO2 %') }}</span>
      <span class="col-span-1 place-self-center">{{ t('AF (1/min)') }}</span>
      <span class="col-span-1 place-self-center">{{ t('Temp °C') }}</span>
    </div>
    <div class="grid grid-cols-8 gap-4 text-body-1 !text-sm text-primary-text py-2 px-6">
      <span
        data-testid="inv-sbp"
        class="col-span-2 px-2 py-1 bg-neutral-100 w-fit rounded place-self-center"
        >{{ INV_S_BP + ' | ' + INV_D_BP + ' | ' + INV_M_BP }}</span
      >
      <span
        data-testid="non-inv-sbp"
        class="col-span-2 px-2 py-1 bg-neutral-100 w-fit rounded place-self-center"
        >{{ NON_INV_S_BP + ' | ' + NON_INV_D_BP + ' | ' + NON_INV_M_BP }}</span
      >
      <span
        data-testid="heart-rate"
        class="col-span-1 px-2 py-1 bg-neutral-100 w-fit rounded place-self-center"
        >{{ HEART_RATE }}</span
      >
      <span
        data-testid="spo2"
        class="col-span-1 px-2 py-1 bg-neutral-100 w-fit rounded place-self-center"
        >{{ SPO2 }}</span
      >
      <span
        data-testid="rr"
        class="col-span-1 px-2 py-1 bg-neutral-100 w-fit rounded place-self-center"
        >{{ RR }}</span
      >
      <span
        data-testid="temp"
        class="col-span-1 px-2 py-1 bg-neutral-100 w-fit rounded place-self-center"
        >{{ TEMP }}</span
      >
    </div>
    <DividerLine />
  </div>
</template>

<script setup lang="ts">
import DividerLine from '@/atoms/DividerLine.vue'
import type { VitalSignsWithTypesDTO } from '@/generated'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'
import { findLatestValueByKey } from './VitalInfoTable.utils'

const { t } = useI18n()

const { vitalSigns } = defineProps<{
  vitalSigns: VitalSignsWithTypesDTO[]
}>()

const INV_S_BP = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.INV_S_BP))
const INV_D_BP = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.INV_D_BP))
const INV_M_BP = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.INV_M_BP))
const NON_INV_S_BP = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.NON_INV_S_BP))
const NON_INV_D_BP = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.NON_INV_D_BP))
const NON_INV_M_BP = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.NON_INV_M_BP))
const HEART_RATE = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.HEART_RATE))
const SPO2 = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.SPO2))
const RR = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.RR))
const TEMP = computed(() => findLatestValueByKey(vitalSigns, VitalSignsKeys.TEMP))
</script>
