import { createApp } from 'vue'
import { createPinia, type Pinia } from 'pinia'
import App from '@/App.vue'
import router from '@/router/router'
import { AuthenticationService } from '@/services/AuthenticationService'
import { useUserStore } from '@/stores/user'
import i18n from '@/i18n/i18n'
import vuetify from '@/plugins/vuetify'
import { UserSettingsService } from '@/services/telehealthApi/UserSettingsService'
import { PushNotificationService } from '@/services/PushNotificationService'
import './index.css'
import * as Sentry from '@sentry/vue'
import { getSentryDsn, getSentryEnvironment } from './global/environmentGetters'

const pinia: Pinia = createPinia()
useUserStore(pinia)
AuthenticationService.initializeKeycloak().then(() =>
  UserSettingsService.syncUserWithBackend().then(() => {
    const app = createApp(App)

    Sentry.init({
      app,
      dsn: getSentryDsn(),
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: getSentryEnvironment()
    })
    app.use(router)
    app.use(i18n)
    app.use(vuetify)
    app.use(pinia)
    app.mount('#app')
    PushNotificationService.activateNotifications()
    UserSettingsService.loadAssignedHospitalsAndWards()
  })
)
