<template>
  <VRow
    v-if="showActionButtons"
    data-testid="receiveractionbuttons"
    no-gutters
    class="flex gap-2 ml-2 justify-end items-end flex-nowrap"
  >
    <VBtn
      v-if="isIncomingRequest && userCanDecline"
      :disabled="hasGodModeShift"
      data-testid="decline-btn"
      variant="outlined"
      color="primary"
      class="button-medium button-outline-primary"
      @click.prevent="declineConsultationRequest"
    >
      {{ t('Decline') }}
    </VBtn>
    <VBtn
      v-if="userCanAccept(hasChiefPhysicianShift)"
      :disabled="hasGodModeShift"
      data-testid="accept-btn"
      variant="outlined"
      color="primary"
      class="button-outline-primary button-medium"
      @click.prevent="acceptConsultationRequest"
    >
      {{ t('I will call back') }}
    </VBtn>
    <StartCallButton
      v-if="userCanStartCall"
      :disabled="hasGodModeShift"
      class="button-medium"
      data-testid="answer-btn"
      :variant="callButtonVariant"
      :consultation-request-id="consultationId"
    />
    <VBtn
      v-if="userCanWriteReport"
      :disabled="hasGodModeShift"
      data-testid="report-btn"
      :to="{
        name: routeNames.WRITE_REPORT,
        params: { consultationRequestId: consultationId }
      }"
      color="primary"
      class="button-medium"
    >
      {{ t('Write report') }}
    </VBtn>
    <DeleteConsultationButton
      v-if="userCanDelete(hasChiefPhysicianShift)"
      data-testid="delete-btn"
      @confirm="onDelete"
    />
  </VRow>
</template>

<script setup lang="ts">
import { type ConsultationRequestDTO } from '@/generated'
import { useI18n } from 'vue-i18n'
import { computed, inject } from 'vue'
import { useConsultationRequestStore } from '@/stores/consultationRequest'
import DeleteConsultationButton from '@/components/consultationrequest/DeleteConsultationButton.vue'
import { PostDeleteConsultationRequestActionKey } from '@/util/symbols'
import { routeNames } from '@/router/routes'
import StartCallButton from '@/components/consultationrequest/StartCallButton.vue'
import { useConsultationRequest } from '@/composables/useConsultationRequest'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { ButtonVariant } from './ButtonVariant'

const props = withDefaults(
  defineProps<{
    consultation: ConsultationRequestDTO
    showActionButtons?: boolean
  }>(),
  {
    showActionButtons: true
  }
)

const postDeleteConsultationRequestAction = inject(PostDeleteConsultationRequestActionKey, () => {})

const { t } = useI18n()
const { deleteOutgoingConsultationRequestById } = useConsultationRequestStore()
const { hasGodModeShift, hasChiefPhysicianShift } = storeToRefs(useUserStore())

const {
  consultationId,
  isIncomingRequest,
  userCanDecline,
  userCanAccept,
  userCanStartCall,
  userCanWriteReport,
  userCanDelete,
  isStatusCreated,
  declineConsultationRequest,
  acceptConsultationRequest
} = useConsultationRequest(props.consultation)

const callButtonVariant = computed<ButtonVariant>(() => {
  if (isStatusCreated.value) return ButtonVariant.ANSWER
  if (userCanWriteReport.value) return ButtonVariant.POST_CALL
  return ButtonVariant.CALL_BACK
})

async function onDelete(): Promise<void> {
  await deleteOutgoingConsultationRequestById(consultationId.value)
  postDeleteConsultationRequestAction()
}
</script>
