import {
  type ConsultationRequestDTO,
  ConsultationRequestStatusDTO,
  ConsultationRequestUserRelationDTO,
  EscalationLevelDTO,
  UrgencyDTO
} from '@/generated'
import { computed } from 'vue'
import { ConsultationRequestService } from '@/services/telehealthApi/ConsultationRequestService'
import { NoticeType } from '@/stores/models/Notice'
import { useNoticeStore } from '@/stores/notice'
import { useConsultationRequestStore } from '@/stores/consultationRequest'

const declinableEscalationLevels: EscalationLevelDTO[] = [
  EscalationLevelDTO.FIRST_SHIFT,
  EscalationLevelDTO.BACKUP_SHIFT,
  EscalationLevelDTO.TCC
]

const preCallConsultationRequestStatuses: ConsultationRequestStatusDTO[] = [
  ConsultationRequestStatusDTO.CREATED,
  ConsultationRequestStatusDTO.ACCEPTED
]

const deletableStatuses: ConsultationRequestStatusDTO[] = [
  ConsultationRequestStatusDTO.CREATED,
  ConsultationRequestStatusDTO.ACCEPTED,
  ConsultationRequestStatusDTO.IN_PROGRESS
]

export function useConsultationRequest(consultationRequest: ConsultationRequestDTO) {
  const caseId = computed<string>(() => consultationRequest.case.id)
  const medicalHistory = computed<string | undefined>(() => consultationRequest.medicalHistory)
  const diagnosis = computed<string>(() => consultationRequest.diagnosis)
  const therapy = computed<string | undefined>(() => consultationRequest.therapy)
  const question = computed<string>(() => consultationRequest.question)
  const consultationId = computed<string>(() => consultationRequest.id)
  const noticeStore = useNoticeStore()
  const { refreshIncomingConsultationRequestList } = useConsultationRequestStore()

  const isOutgoingRequest = computed<boolean>(
    () => consultationRequest.relationToUser === ConsultationRequestUserRelationDTO.OUTGOING
  )

  const isIncomingRequest = computed<boolean>(
    () => consultationRequest.relationToUser === ConsultationRequestUserRelationDTO.INCOMING
  )

  const isEmergencyRequest = computed<boolean>(
    () => consultationRequest.urgency === UrgencyDTO.EMERGENCY
  )

  const isStatusPreCall = computed<boolean>(() =>
    preCallConsultationRequestStatuses.includes(consultationRequest.status)
  )

  const isStatusCreated = computed<boolean>(
    () => consultationRequest.status === ConsultationRequestStatusDTO.CREATED
  )

  const userCanAccept = (isUserChiefPhysician: boolean) =>
    isStatusCreated.value && (isUserChiefPhysician || isIncomingRequest.value)

  const userCanDecline = computed<boolean>(
    () =>
      declinableEscalationLevels.includes(consultationRequest.escalationLevel) &&
      isStatusPreCall.value
  )

  const userCanDelete = (isUserChiefPhysician: boolean) =>
    isUserChiefPhysician ||
    (deletableStatuses.includes(consultationRequest.status) && isOutgoingRequest.value)

  const userCanStartCall = computed<boolean>(
    () => isIncomingRequest.value && !isEmergencyRequest.value
  )

  const userCanWriteReport = computed<boolean>(
    () =>
      consultationRequest.status === ConsultationRequestStatusDTO.REPORT_PENDING &&
      isIncomingRequest.value
  )

  async function declineConsultationRequest(): Promise<void> {
    try {
      await ConsultationRequestService.declineConsultationRequest(consultationId.value)
      await refreshIncomingConsultationRequestList()
    } catch (error: any) {
      console.error((error as Error).message)
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Declining the consultation request {id} failed',
        messageParameters: { id: consultationId.value }
      })
    }
  }

  async function acceptConsultationRequest(): Promise<void> {
    try {
      await ConsultationRequestService.acceptConsultationRequest(consultationId.value)
      await refreshIncomingConsultationRequestList()
    } catch (error: any) {
      console.error((error as Error).message)
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Accepting the consultation request {id} failed',
        messageParameters: { id: consultationId.value }
      })
    }
  }

  return {
    caseId,
    medicalHistory,
    diagnosis,
    therapy,
    question,
    consultationId,
    isOutgoingRequest,
    isIncomingRequest,
    userCanDecline,
    isEmergencyRequest,
    userCanStartCall,
    userCanWriteReport,
    userCanDelete,
    userCanAccept,
    isStatusCreated,
    acceptConsultationRequest,
    declineConsultationRequest
  }
}
