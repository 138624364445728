import 'material-icons/iconfont/filled.css'
import 'material-icons/iconfont/round.css'
import 'material-icons/iconfont/outlined.css'
import '@/sass/main.scss'

import { createVuetify } from 'vuetify'
import { aliases, md } from 'vuetify/iconsets/md'
import { lightTheme } from '@/plugins/themes'
import { vuetifyDefaults } from '@/plugins/defaults'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import i18n from '@/i18n/i18n'
import { useI18n } from 'vue-i18n'
import { customIcons } from '@/plugins/customIcons'

export default createVuetify({
  defaults: vuetifyDefaults,
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
      custom: customIcons
    }
  },
  theme: {
    defaultTheme: 'lightTheme',
    themes: { lightTheme }
  },
  locale: {
    // @ts-ignore
    adapter: createVueI18nAdapter({ i18n, useI18n })
  }
})
