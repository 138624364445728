<template>
  <VCard :to="{ name: routeNames.PATIENT_DETAILS, params: { caseId: caze.id } }" class="mb-4">
    <VCardItem>
      <VCardTitle class="text-h5 text-primary-text mb-2">
        <VCardTitle class="flex justify-space-between flex-wrap gap-2">
          <PatientTitle :patient="caze.patient" />
        </VCardTitle>
      </VCardTitle>

      <VCardSubtitle class="flex justify-space-between p-0 max-sm:flex-col max-sm:gap-2">
        <PatientDetails :caze="caze" class="text-body-2" />
        <VTooltip location="start" :disabled="!disableEmergencyButton">
          {{ t('No physicians available in the Intensive Care Department for emergency calls.') }}
          <template #activator="activator">
            <span v-bind="activator.props" @click.prevent>
              <VBtn
                variant="outlined"
                color="secondary"
                class="button-medium"
                data-testid="emergency-btn"
                :loading="createEmergencyInProgress"
                :disabled="disableEmergencyButton || hasGodModeShift"
                @click.prevent="onEmergency()"
              >
                {{ t('Emergency') }}
              </VBtn>
            </span>
          </template>
        </VTooltip>
      </VCardSubtitle>
    </VCardItem>

    <div v-if="vitalSigns" class="flex flex-col mt-2 gap-2 pb-4 sm:px-4">
      <DividerLine />
      <VCardItem class="p-0">
        <div class="overflow-x-auto">
          <VitalInfoTable :vital-signs="vitalSigns" />
        </div>
      </VCardItem>

      <div class="mt-4 flex justify-center">
        <VBtn
          :active="false"
          color="primary"
          :append-icon="showMoreDetails ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          class="button-medium button-outline-primary"
          variant="outlined"
          size="medium"
          @click.prevent="toggleMoreDetails()"
        >
          {{ showMoreDetails ? t('Show less') : t('Show more') }}
        </VBtn>
      </div>

      <div v-if="showMoreDetails">
        <VCardItem v-if="organSupportCount > 0" class="p-0 sm:px-4">
          <h4 class="m-2 text-subtitle-1 text-secondary-text">{{ t('Organ support') }}</h4>

          <div class="overflow-x-auto">
            <OrganSupportTable :values="tempData.organSupport" />
          </div>
        </VCardItem>

        <!-- <VCardItem class="p-0 sm:px-4">
          <h4 class="m-2 text-subtitle-1 text-secondary-text">{{ t('Laboratory values') }}</h4>

          <div class="overflow-x-auto">
            <VitalInfoTable :vital-signs="vitalSigns" />
          </div>
        </VCardItem>

        <VCardItem class="p-0 sm:px-4">
          <h4 class="m-2 text-subtitle-1 text-secondary-text">{{ t('Scorings') }}</h4>

          <div class="overflow-x-auto">
            <VitalInfoTable :vital-signs="vitalSigns" />
          </div>
        </VCardItem> -->
      </div>
    </div>
  </VCard>
</template>

<script setup lang="ts">
import { type CaseDTO, type VitalSignsWithTypesDTO } from '@/generated'
import { useI18n } from 'vue-i18n'
import PatientDetails from '@/components/patient/components/atoms/PatientDetails.vue'
import PatientTitle from '@/components/patient/components/atoms/PatientTitle.vue'
import { routeNames } from '@/router/routes'
import { ConsultationRequestService } from '@/services/telehealthApi/ConsultationRequestService'
import { NoticeType } from '@/stores/models/Notice'
import { useNoticeStore } from '@/stores/notice'
import { useCallStore } from '@/stores/call'
import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import DividerLine from '@/atoms/DividerLine.vue'
import OrganSupportTable from './OrganSupportTable.vue'
import VitalInfoTable from './VitalInfoTable.vue'
import { usePatientDataSettingsStore } from '@/stores/patientDataSettings'

const props = defineProps<{
  caze: CaseDTO
  vitalSigns?: VitalSignsWithTypesDTO[]
}>()

const { t } = useI18n()
const noticeStore = useNoticeStore()
const callStore = useCallStore()
const { hasGodModeShift } = storeToRefs(useUserStore())
const patientDataSettingsStore = usePatientDataSettingsStore()

const organSupportCount = computed(
  () =>
    Object.values(patientDataSettingsStore.patientDataSettings.organSupport).filter((el) => el)
      .length
)

const createEmergencyInProgress = ref<boolean>(false)
const showMoreDetails = ref<boolean>(false)

const disableEmergencyButton = computed<boolean>(() => !!props.caze.emergencyUnavailable)

async function onEmergency() {
  try {
    createEmergencyInProgress.value = true
    const createdRequestId = await ConsultationRequestService.createEmergencyRequest(props.caze.id)
    await callStore.joinCall(createdRequestId)
  } catch (error: any) {
    console.error((error as Error).message)
    noticeStore.add({
      type: NoticeType.ERROR,
      message: 'Failed to submit consultation request'
    })
  } finally {
    createEmergencyInProgress.value = false
  }
}

function toggleMoreDetails() {
  showMoreDetails.value = !showMoreDetails.value
}

const tempData = {
  // TODO: remove this after backend is updated
  organSupport: {
    vasopressors: true,
    ventilation: true,
    dialysis: false,
    ecmo: false,
    impella: true
  }
}
</script>

<style scoped>
.emergency-btn {
  border-color: rgba(245, 0, 87, 0.5);
}
</style>
