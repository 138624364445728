import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
  type MessagePayload,
  type Messaging
} from 'firebase/messaging'
import { useUserStore } from '@/stores/user'
import { UserService } from '@/services/telehealthApi/UserService'
import { useNoticeStore } from '@/stores/notice'
import { NoticeType } from '@/stores/models/Notice'
import { urlSafeBase64 } from '@/util/base64'

export class PushNotificationService {
  static messaging: Messaging | undefined = undefined

  static async initFirebase(): Promise<void> {
    try {
      const firebaseOptions = useUserStore().firebaseOptions

      if (firebaseOptions === undefined) {
        throw new Error('could not find firebase options!')
      }

      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.register(
          '/firebase-messaging-sw.js?config=' + urlSafeBase64(JSON.stringify(firebaseOptions)),
          { type: 'classic' }
        )

        const firebaseApp = initializeApp(firebaseOptions)
        PushNotificationService.messaging = getMessaging(firebaseApp)

        try {
          // one device multiple user, prevent receiving notifications of another user
          await deleteToken(PushNotificationService.messaging)
        } catch (error: any) {
          console.warn(error)
        }

        const currentToken = await getToken(PushNotificationService.messaging, {
          vapidKey: useUserStore().firebaseOptions?.vapidKey,
          serviceWorkerRegistration: registration
        })

        useUserStore().setNotificationToken(currentToken)
        await UserService.login(useUserStore().user?.userName ?? '', {
          notificationToken: currentToken
        })

        onMessage(PushNotificationService.messaging, async (payload: MessagePayload) => {
          if (!('Notification' in window)) {
            useNoticeStore().add({
              type: NoticeType.ERROR,
              message: "Your Device/Browser doesn't support push notifications!"
            })
          } else if (Notification.permission === 'granted') {
            await showNewNotification(registration, payload)
          } else if (Notification.permission !== 'denied') {
            const permission = await Notification.requestPermission()
            if (permission === 'granted') {
              await showNewNotification(registration, payload)
            } else {
              useNoticeStore().add({
                type: NoticeType.WARNING,
                message:
                  'Push notifications are not allowed yet for e:lmo. Please allow push notifications from your profile or App/Browser'
              })
            }
          }
        })
      } else {
        useNoticeStore().add({
          type: NoticeType.WARNING,
          message: 'Requirements not met for push notifications.'
        })
      }
    } catch (error: any) {
      console.error('Error initializing firebase: ', error)
    }
  }

  static async activateNotifications(): Promise<void> {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      await PushNotificationService.initFirebase()
    } else {
      useNoticeStore().add({
        type: NoticeType.WARNING,
        message:
          'Push notifications are not allowed yet for e:lmo. Please allow push notifications from your profile or App/Browser'
      })
    }
  }

  static async deactivateNotificationReception(): Promise<void> {
    try {
      if (PushNotificationService.messaging !== undefined) {
        await deleteToken(PushNotificationService.messaging)
      }

      if (useUserStore().notificationToken !== undefined) {
        await UserService.logoutDevice(useUserStore().user?.userName ?? '', {
          notificationToken: useUserStore().notificationToken ?? ''
        })

        useUserStore().clearNotificationToken()
      }
    } catch (error: any) {
      console.log('Error by deactivating notification reception: ', error)
    }
  }
}

async function showNewNotification(
  registration: ServiceWorkerRegistration,
  payload: MessagePayload
): Promise<void> {
  if (!payload.data || !payload.data.title) return
  await registration.showNotification(payload.data.title, {
    body: payload.data.text,
    icon: '/icon-192x192.png',
    data: { url: payload.data.link }
  })
}
